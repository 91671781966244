import { isMobile } from './isMobile'

/*
 *
 * ----------------------------------------------- */
jQuery(function ($) {
  var $btn = $('.page-top') // page top btn

  var btnFixedPosition = 0

  if (isMobile()) {
    btnFixedPosition = 10
  } else {
    btnFixedPosition = 55
  }

  var yTop = 0 // トップの縦位置（ピクセル）
  var pxInBackToTop = 180 + yTop // どれくらい下へスクロールしたらトップへ戻るボタンを出すか（ピクセル）
  var msInBTTBtn = 300 // トップへ戻るボタンが出てくるアニメーションの時間（ミリ秒）
  var msBTTScroll = 800 // トップへ戻るスクロールアニメーションの時間（ミリ秒）

  // トップへ戻るボタン
  // var yDefBttBtn = parseInt($btn.css('bottom'))
  var btnDefaultY = '-100px'
  $btn.css('bottom', btnDefaultY)

  var isInBttBtn = false
  // var isOverFooter = false

  $(window)
    .scroll(setBttPos)
    .resize(setBttPos)

  function setBttPos () {
    var y = $(window).scrollTop()

    if (y > pxInBackToTop) {
      if (!isInBttBtn) {
        isInBttBtn = true

        $btn.stop().animate({
          bottom: btnFixedPosition + 'px'
        }, msInBTTBtn, 'easeOutBack')
      }
    } else {
      if (isInBttBtn) {
        isInBttBtn = false

        $btn.stop().animate({
          bottom: btnDefaultY
        }, msInBTTBtn, 'easeInBack')
      }
    }
  }

  // move to page top
  $btn.on('click', function (e) {
    $('html, body').stop().animate({
      scrollTop: yTop
    }, msBTTScroll, 'easeOutQuint')

    e.preventDefault()
  })
})
