document.addEventListener('DOMContentLoaded', function () {
  const radios = document.querySelectorAll('input[type="radio"]')

  radios.forEach(radio => {
    radio.addEventListener('change', function () {
      const targetId = radio.getAttribute('data-target')
      const groupName = radio.name
      const groupRadios = document.querySelectorAll(`input[name="${groupName}"]`)

      if (targetId) {
        const targetInput = document.getElementById(targetId)

        if (radio.checked) {
          targetInput.disabled = false
        } else {
          targetInput.disabled = true
          targetInput.value = ''
        }
      }

      // Disable and clear other inputs in the same group
      groupRadios.forEach(gradio => {
        if (gradio !== radio && gradio.getAttribute('data-target')) {
          const otherTargetId = gradio.getAttribute('data-target')
          const otherTargetInput = document.getElementById(otherTargetId)
          otherTargetInput.disabled = true
          otherTargetInput.value = ''
        }
      })
    })
  })
})
