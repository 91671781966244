import './polyfill/number-is-nan'
// import './modules/swiper'
// import './modules/aos'

// You can specify which plugins you need
// import { Tooltip, Toast, Popover } from "bootstrap";
import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/scrollspy'

import './modules/webfont'
// import './modules/view-more'
// import './modules/page-top'
// import './modules/toggle-disabled-input-field'
import './modules/jqueryvalidation'
import './modules/menu-trigger'
// import './modules/bs.tab'
// import './modules/js-header'
// import './modules/js-footer'
// import './modules/waypoints'
// import './modules/dropdown-hover'
import './modules/smooth-scroll'
import './modules/datepicker'

import './modules/jquery-match-height'
import './modules/radio-other'
// import './modules/luxy'
// import './modules/banner-fixed-bottom'

// import ScrollMagic from "scrollmagic";
import './modules/grBackToTop'
