import { isMobile } from './isMobile'

/*
 * menu-trigger btn
 * ----------------------------------------------- */
jQuery(function ($) {
  var $menuTrigger = $('.menu-trigger')

  if (isMobile()) {
    $('.navbar-gr-laboratory .nav-link').on('click', function () {
      $menuTrigger[0].click()
    })
  }
})
